@charset "UTF-8";

@font-face {
font-family:Oswald;
font-style:normal;
font-weight:400;
src:local('Oswald Regular'), local(Oswald-Regular), url(//themes.googleusercontent.com/static/fonts/oswald/v7/-g5pDUSRgvxvOl5u-a_WHw.woff) format(woff);
}

@font-face {
	font-family: 'helvetica-bold';
	src: url('../fonts/helvetica-bold.eot');
	src: local('Helvetica Bold'), url('../fonts/helvetica-bold.woff') format('woff'), url('../fonts/helvetica-bold.ttf') format('truetype'), url('../fonts/helvetica-bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'helvetica-regular';
	src: url('../fonts/helvetica-regular.eot');
	src: local('Helvetica Regular'), url('../fonts/helvetica-regular.woff') format('woff'), url('../fonts/helvetica-regular.ttf') format('truetype'), url('../fonts/helvetica-regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

body {
background-color:#fff;
}

nav,.vertnav-top li a,#mobnav-trigger {
text-align:left;
}

.gen-slider-arrows1-pos-top-right .direction-nav {
top:0!important;
}

.nav-wrapper {
position:relative;
}

.user-body #user_contents h1 {
text-align:left;
}

.deline {
color:#d44950;
text-decoration:line-through;
}

div[class^=account] p,div[class^=account] h2 {
text-align:left;
}

div[class^=account] .form-list input.input-text {
float:left;
}

.lang-switcher span.dropdown-icon {
float:left;
}

div.section-title {
text-align:left;
}

div.page-content {
text-align:left;
}

#nav .level0-wrapper {
-webkit-transform:translateZ(0)!important;
}

ul.products-page {
height:160px;
}

.thumbnails li a {
padding:0!important;
}

.products-page a {
height:65px;
}

.product-view .product-shop {
text-align:left;
}

.page {
}

.footer-container {
}

.block-layered-nav {
text-align:left;
}

li.item_list {
margin-left:25px;
float:left;
width:200px!important;
height:250px;
padding-bottom:25px;
}

div.std {
text-align:left;
}

img#main_item_image {
text-align:center;
}

.cms-home .custom-listing-wrapper.recommended_shoes_slider {
float:left;
width:48%;
}

.cms-home .custom-listing-wrapper.recommended_bags_slider {
float:right;
width:48%;
}

.nav-regular .nav-submenu--mega > li {
float:left;
}

.nav-container .section-title.padding-right {
margin-bottom:5px;
}

.highslide-container {
z-index:2050!important;
}

.horizontal-section {
margin:0 initial;
}

.horizontal-section .col-main {
float:inherit;
}

.horizontal-section .page-content {
margin:0 10px;
padding:0;
}

.messages {
overflow:intial;
margin:0 0 10px;
clear:both;
}

.products-grid.single-line-name .product-name {
overflow:visible;
text-overflow:initial;
word-wrap:break-word;
white-space:normal;
}

#override_shipping_value {
text-align:right;
width:50px!important;
}

.sp-methods label,.sp-methods span.price {
display:inline-block;
}

/* Tri-state content styling */
.tristate-content h5 {
clear:both;
color:#0067a6;
font-size:16px;
line-height:19px;
}

.tristate-content h2 {
background:none repeat scroll 0 0 #0067a6;
color:#fff;
float:left;
font-size:36px;
margin:0 0 5px -15px;
padding:0 30px 0 15px;
}

.tristate-content a {
color:#08C;
}

/* Tri-state content styling */
#filter_current_box {
background-color:#f5f5f5;
padding:10px;
}

#filter_current_box > p {
margin:0;
}

#filter_current_box > ol > li {
background:url(/assets/app/public/images/magento/img/bullet.png) 0 5px no-repeat;
padding:0 0 0 15px;
transition:background-position 150ms ease-out 0;
}

#filter_current_box > ol > li:hover {
background-position:5px 5px;
}

#filter_current_box > ol > li > span.label {
color:#d90000;
}

#filter_action_box > a {
float:right;
}

#filter_list_box dt {
margin:10px 0;
font-weight:700;
}

#filter_list_box dd > ol > li {
background:url(/assets/app/public/images/magento/img/bullet.png) 0 5px no-repeat;
padding:0 0 0 15px;
transition:background-position 150ms ease-out 0;
color:#aaa;
}

#filter_list_box dd > ol > li:hover {
background-position:5px 5px;
}

#filter_list_box dd > ol > li > a {
color:#333;
}

.contactus input {
min-width:350px;
}

.contactus textarea {
min-width:450px;
min-height:50px;
}

.header-container {
	padding-bottom: 0px;
}

@media only screen and (max-width: 767px) {
	.mobnav-trigger-wrapper {
		width: 98% !important;
	}
}


.qty-wrapper {
font-size:1em;
margin:5px 0 10px;
}

/*.products-list .product-image img, .category-products-grid .product-image img {
width:100%;
}*/

.products-line .product-image img {
width:44%;
}

.products-line .addto-links-icons.addto-onimage {
float:left;
margin-top:-32px;
position:relative;
z-index:1;
padding-bottom:4px;
padding-right:4px;
margin-left:65px;
}

.products-line .qty-wrapper {
float:right;
margin-top:-33px;
margin-right:29px;
}

.wishlist-items .heading {
	position:absolute;
	margin:20px 0 0 200px;
	font-size:2em;
	font-family:"Bitter",georgia,serif;
}

.wishlist-items .image {
	float:left;
}
.wishlist-items .price {
	float:left;
	margin:45px 0 0 2px;
}
.wishlist-items .feature {
	float:left;
}
